import {
    Button,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalOverlay,
    Text
} from '@chakra-ui/react';
import * as React from "react";
import { createContext, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

const UserContext = createContext();

export const useUser = () => useContext(UserContext);

export const UserProvider = ({ children }) => {
    const location = useLocation();
    const [guid, setGuid] = useState(null);
    const [pharmacy, setPharmacy] = useState(null);
    const [source, setSource] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [firstVisitKey, setFirstVisitKey] = useState(null);

    useEffect(() => {
        let guid = localStorage.getItem('guid');
        console.log('hello')
        if (!guid) {
            guid = uuidv4();
            localStorage.setItem('guid', guid || '');
        }
        setGuid(guid);

        setPharmacy(localStorage.getItem('pharmacy'));
        setSource(localStorage.getItem('campaign'));
        setFirstVisitKey(localStorage.getItem('firstSymVisitKey'));

        const queryParams = new URLSearchParams(location.search);
        queryParams.forEach((value, key) => {
            localStorage.setItem(key, value);
        });

        if (localStorage.getItem('source') && localStorage.getItem('source').includes('gianteagle')) {
            console.log(`source param: ${localStorage.getItem('source')}`)
            localStorage.setItem('pharmacy', 'Giant Eagle')
          }

          console.log('pharmacy key for modal', localStorage.getItem('pharmacy'))
    
        if (
            localStorage.getItem('firstSymVisitKey') === null &&
            localStorage.getItem('pharmacy') !== null
        ) {
            setShowModal(true);
            localStorage.setItem('firstSymVisitKey', 'false');
        }
    }, [location]);

    return (
        <UserContext.Provider value={{ 
            guid, setGuid,
            pharmacy, setPharmacy,
            source, setSource,
            showModal, setShowModal,
            firstVisitKey, setFirstVisitKey,
        }}>
            
            <>
            {pharmacy &&
                <Modal isOpen={showModal} onClose={() => setShowModal(false)} size={'xl'}>
                    <ModalOverlay />
                    <ModalContent>
                    <ModalCloseButton />
                    <ModalBody>
                        <Text mt={14} mb={8} fontSize={'2em'} textAlign={'center'}>Welcome {pharmacy} Customers!</Text>
                    </ModalBody>
                    <ModalFooter>
                        <Button variant="primaryBlueSolid" margin="0 auto 10px auto"
                        onClick={() => setShowModal(false)}
                        >
                        Get Started
                        </Button>
                        {/* You can add more buttons or content here as needed */}
                    </ModalFooter>
                    </ModalContent>
                </Modal>
            }
            </>
            {children}
        </UserContext.Provider>
    );
};